import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import MultiSelectDropDownInput from '../CommonComponents/MultiSelectComponent/MultiSelectDropDownInput';
import { EXEC_TITLES } from '../Constants';

export default function TemplateFilters({ disabled, filters, handleFilteringOptions, handleFilterUpdate, findMatchingOptions, toggleMultiSelectedOptions }) {
    return (
        <>
            {filters.length > 0 &&
                <Row className="mt-1">
                    {filters.slice(0, 4).map((filter, index) => <Col md={3} key={filter.fieldName}>
                        <Form.Group controlId={filter.fieldName}>
                            <Form.Label>
                                <span>{filter.fieldName}</span>
                            </Form.Label>
                            <MultiSelectDropDownInput
                                disabled={disabled}
                                options={filter.options}
                                toggleOption={({ id }) => toggleMultiSelectedOptions(id, filter)}
                                clearAll={() => handleFilterUpdate(filter, [])}
                                optionSearch={option => {
                                    if (option === null) return;

                                    if (option === "") {
                                        handleFilteringOptions(filter, EXEC_TITLES);
                                        return;
                                    }

                                    option = option.trim();
                                    const optionLower = option.toLowerCase();
                                    const matchedOptions = findMatchingOptions(optionLower, filter.options);
                                    handleFilteringOptions(filter, matchedOptions);
                                }} />
                        </Form.Group>
                    </Col>)}
                </Row>}
            {filters.length > 4 &&
                <Row>
                    {filters.slice(4).map(filter => (
                        <Col md={4} key={filter.fieldName}>
                            <Form.Group controlId={filter.fieldName}>
                                <Form.Label>{filter.fieldName}</Form.Label>
                                <Form.Control as="select" onChange={(e) => handleFilterUpdate(filter, e.target.value)} disabled={disabled}>
                                    <option>Loading...</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>))}
                </Row>}
        </>);
}
