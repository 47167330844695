import { v4 as uuidv4 } from 'uuid';

export const TEMPLATES_URL = "/templates";

export const DATA_PROVIDER = "Data_Provider";

export const NONE = "None";

export const HIDDEN_COLUMNS = ["Company ID", "Employee ID"];

// TODO: move these to the database, so that they can be changed without redeploying the app and they can be centralized
export const DATA_VALIDATION_TYPES = {
   REQUIRED: "REQUIRED",
   ND_REQUIRED: "ND_REQUIRED",
   NA_REQUIRED: "NA_REQUIRED",
   MARKER: "MARKER",
   ND_NOT_REQUIRED: "ND_NOT_REQUIRED",
   NOT_REQUIRED_NOT_ND_NA: "NOT_REQUIRED_NOT_ND_NA",
   NOT_REQUIRED: "NOT_REQUIRED",
};

export const TEMPLATE_TYPE = {
   EXECUTIVE: "Executive",
   BROAD_BASED: "Broad-based",
   SURVEY: "Survey"
};

export const DATA_TYPES = {
   STRING: "STRING",
   NUMBER: "NUMBER",
   DATE: "DATE",
   COMPOSITE_COMPANY_ID: "COMPOSITE_COMPANY_ID",
   CURRENCY: "CURRENCY"
};

export const TICKER_COLUMN_DEFINITION = {
   tempId: uuidv4(),
   name: "Ticker",
   dataType: DATA_TYPES.COMPOSITE_COMPANY_ID,
   validations: DATA_VALIDATION_TYPES.REQUIRED,
   position: "",
   sheetName: "",
   isSummaryAttribute: true,
   isFilterable: false,
   isTitleCode: false,
   secondaryReview: false
};

export const STANDARD_COLUMNS = [
   TICKER_COLUMN_DEFINITION,
   {
      tempId: uuidv4(),
      name: "Company ID",
      dataType: DATA_TYPES.STRING,
      validations: DATA_VALIDATION_TYPES.REQUIRED,
      position: "",
      sheetName: "",
      isSummaryAttribute: true,
      isFilterable: false,
      isTitleCode: false,
      secondaryReview: false
   },
   {
      tempId: uuidv4(),
      name: "Company Name",
      dataType: DATA_TYPES.STRING,
      validations: DATA_VALIDATION_TYPES.REQUIRED,
      position: "",
      sheetName: "",
      isSummaryAttribute: true,
      isFilterable: false,
      isTitleCode: false,
      secondaryReview: false
   }
];

const RESEARCH_ANALYSIS_STANDARD_COLUMNS = [
   ...STANDARD_COLUMNS,
   {
      tempId: uuidv4(),
      name: "FYE",
      dataType: DATA_TYPES.DATE,
      validations: DATA_VALIDATION_TYPES.REQUIRED,
      position: "",
      sheetName: "",
      isSummaryAttribute: true,
      isFilterable: false,
      isTitleCode: false,
      secondaryReview: false
   }
];

export const EXECUTIVE_STANDARD_COLUMNS = [
   ...RESEARCH_ANALYSIS_STANDARD_COLUMNS,
   {
      tempId: uuidv4(),
      name: "Employee ID",
      dataType: DATA_TYPES.STRING,
      validations: DATA_VALIDATION_TYPES.REQUIRED,
      position: "",
      sheetName: "",
      isSummaryAttribute: true,
      isFilterable: false,
      isTitleCode: false,
      secondaryReview: false
   },
   {
      tempId: uuidv4(),
      name: "Employee Last Name",
      dataType: DATA_TYPES.STRING,
      validations: DATA_VALIDATION_TYPES.REQUIRED,
      position: "",
      sheetName: "",
      isSummaryAttribute: true,
      isFilterable: false,
      isTitleCode: false,
      secondaryReview: false
   }
];

export const BROAD_BASED_STANDARD_COLUMNS = [
   ...RESEARCH_ANALYSIS_STANDARD_COLUMNS
];

export const SURVEY_STANDARD_COLUMNS = [
   ...STANDARD_COLUMNS,
   {
      tempId: uuidv4(),
      name: "Survey Year",
      dataType: DATA_TYPES.NUMBER,
      validations: DATA_VALIDATION_TYPES.REQUIRED,
      position: "",
      sheetName: "",
      isSummaryAttribute: true,
      isFilterable: false,
      isTitleCode: false,
      secondaryReview: false
   }
];

export const NORMALIZED_COMPENSATION_PROP_NAME_MAP = new Map([
   ["FYE", "fye"],
   ["Survey Year", "surveyYear"],
   ["Ticker", "lookupKey"],
   ["Company ID", "companyId"],
   ["Employee ID", "employeeId"],
   ["Executive Name", "employeeName"]
]);

export const NORMALIZED_COMPENSATION_DB_ATTR_NAME_MAP = new Map([
   ["FYE", "fye"],
   ["Survey Year", "survey_year"],
   ["Company Name", "company_name"],
   ["Company ID", "company_id"],
   ["Employee ID", "employee_id"],
   ["Ticker", "ticker"],
   ["Executive Name", "employee_name"],
   ["CD&A Disclosure Rank Order", "cda_disclosure_rank"]
]);

export const NORMALIZED_COMPENSATION_GRID_COLUMNS = [
   "FYE",
   "Survey Year",
   "Company Name",
   "Company ID",
   "Employee ID",
   "Ticker",
   "Executive Name",
   "CD&A Disclosure Rank Order"
];

export const REQUIRED_COMPENSATION_FIELDS = [
   "Company ID",
   "FYE",
   "Company Name",
   "Ticker",
   "Employee ID",
];

const parseTickers = (companyIds) => {
   const tickerDelimiter = /\r?\n/;
   const formattedTickers = new Set();
   companyIds.split(tickerDelimiter).forEach(tks => {
      tks.split(',').forEach(ticker => {
         formattedTickers.add(ticker.trim().toUpperCase());
      });
   });
   return formattedTickers;
}

export const formatTickers = (companyIds) => {
   if (companyIds !== null && companyIds.length > 0) {
      if (Array.isArray(companyIds))
         return companyIds.filter(ticker => ticker !== "," || ticker !== "" || ticker !== " ");
      const formattedTickers = parseTickers(companyIds);
      return [...formattedTickers].map(t => t.toString().trim()).filter(t => t !== "" && t !== ",");
   }
};

export const formatCompanyIds = (companyIds) => {
   if (companyIds !== null && companyIds.length > 0) {
      if (Array.isArray(companyIds))
         return companyIds.filter(ticker => ticker !== "," || ticker !== "" || ticker !== " ");
      const formattedCompanyIds = parseCompanyIds(companyIds);
      return [...formattedCompanyIds].map(t => t.toString().trim()).filter(t => t !== "" && t !== ",");
   }
};

const parseCompanyIds = (companyIds) => {
   const delimiter = /\r?\n/; // new line
   const formattedCompanyIDs = new Set();
   companyIds.split(delimiter).forEach(tks => {
      if (tks.split(",").length > 1) {
         tks.split(",").forEach(tk => {
            formattedCompanyIDs.add(tk.trim().toUpperCase());
         });
         return;
      }
      formattedCompanyIDs.add(tks.trim().toUpperCase());
   });
   return formattedCompanyIDs;
};

export const EXPORTED_DATA_REPORT_HEADERS = ['Downloader', 'Template Name', 'Total Companies Downloaded', 'Client Owner Name', 'Client Owner Email', 'Billable Type', 'Client To Bill', 'Date Downloaded', 'Total Records Downloaded', 'User Notes'];
// TODO: move these to the database, so that they can be changed without redeploying the app and they can be centralized
export const EXEC_TITLES = [
   { id: "CEO", value: "Principal executive officer" },
   { id: "CFO", value: "Principal financial officer" },
   { id: "EXCH", value: "Executive chair of the board" },
   { id: "VCH", value: "Executive vice chair of the board" },
   { id: "COO", value: "Chief operating officer" },
   { id: "OPS", value: "Top operations" },
   { id: "GC", value: "Top legal" },
   { id: "HR", value: "Top human resources" },
   { id: "MAR", value: "Top marketing" },
   { id: "SALE", value: "Top sales" },
   { id: "PDIV", value: "Top division" },
   { id: "CADO", value: "Top administrative" },
   { id: "CAO", value: "Top accounting" },
   { id: "CIO", value: "Top information / IT" },
   { id: "CTO", value: "Top technical / technology" },
   { id: "CSO", value: "Top scientific" },
   { id: "RD", value: "Top research & development" },
   { id: "CMO", value: "Top medical" },
   { id: "IR", value: "Top investor relations" },
   { id: "FIN", value: "Second level finance" },
   { id: "BD", value: "Top business development" },
   { id: "CD", value: "Top corporate development" },
   { id: "RISK", value: "Top risk" },
   { id: "LOAN", value: "Top lending" },
   { id: "WM", value: "Top wealth management" },
   { id: "INV", value: "Top investment" },
   { id: "OTHER", value: "Any role not listed above" }
];