import { Analytics } from 'aws-amplify';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import NavBar from '../Home/NavigationBar/NavBar';
import './styles.scss';
import QuestionnaireUploadModal from './QuestionnaireUploadModal/QuestionnaireUploadModal';

const sampleSurveyFileKey = "sampleSurvey";
const questionnaireFileKey = "surveyQuestionnaire";
const earlySurveyResultEligibleKey = "earlySurveyResult";

const unrestrictedSurveyDataAPI = process.env.REACT_APP_UNRESTRICTED_SURVEY_DATA_API;

export default function SurveyParticipantConsole() {
    const [isLoading, setIsLoading] = useState(true);

    const [quesitionnaireFileLink, setQuesitionnaireFileLink] = useState(null);
    const questionnaireDonwnloadLinkRef = useRef(null);

    const [earlySurveyResultFileLink, setEarlySurveyResultFileLink] = useState(null);
    const earlySurveyResultDonwnloadLinkRef = useRef(null);

    const [sampleSurveyFileLink, setSampleSurveyFileLink] = useState(null);

    const { participantId } = useParams();
    const [participantData, setParticipantData] = useState(null);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const fetchParticipantData = () => {
        axios.get(`${unrestrictedSurveyDataAPI}/unrestricted/survey/participants?id=${participantId}`)
            .then(response => {
                setParticipantData(response.data);
                navigatedToParticipantConsoleEvent(response.data);
                fetchEarlySurveyResultFileLink(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const fetchEarlySurveyResultFileLink = (data) => {
        if (data?.earlySurveyResultEligible) {
            fetchStaticFileLink(earlySurveyResultEligibleKey, setEarlySurveyResultFileLink);
        }
    }

    const fetchStaticFileLink = (fileType, setLink) => {
        axios.get(`${unrestrictedSurveyDataAPI}/unrestricted/survey/files?docType=${fileType}&participantId=${participantId}`)
            .then(response => {
                setLink(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const questionnaireDownloadedEvent = () => {
        Analytics.record({
            name: 'QuestionnaireDownloaded',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName
            }
        });
    }

    const earlySurveyResultEvent = () => {
        Analytics.record({
            name: 'EarlySurveyResultDownloaded',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName
            }
        });
    }

    const sampleSurveyDownloadedEvent = () => {
        Analytics.record({
            name: 'SampleSurveyDownloaded',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName
            }
        });
    }

    const navigatedToParticipantConsoleEvent = (participant) => {
        Analytics.record({
            name: 'NavigatedToParticipantConsole',
            attributes: {
                participantId: participant.participantId,
                participantName: participant.participantName
            }
        });
    }

    const emailAccountExecutiveEvent = () => {
        Analytics.record({
            name: 'EmailAccountExecutive',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName,
                accountExecutiveEmail: participantData.accountExecutiveEmail
            }
        });
    }

    const emailFwcSurveyEvent = () => {
        Analytics.record({
            name: 'EmailFwcSurvey',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName
            }
        });
    }

    const handleDownloadingQuestionnaire = () => {
        if (questionnaireDonwnloadLinkRef.current && quesitionnaireFileLink) {
            questionnaireDonwnloadLinkRef.current.href = quesitionnaireFileLink.url;
            questionnaireDonwnloadLinkRef.current.click();
            questionnaireDownloadedEvent();
        }
    }

    const handleDownloadingEarlySurveyResult = () => {
        if (earlySurveyResultDonwnloadLinkRef.current && earlySurveyResultFileLink) {
            earlySurveyResultDonwnloadLinkRef.current.href = earlySurveyResultFileLink.url;
            earlySurveyResultDonwnloadLinkRef.current.click();
            earlySurveyResultEvent();
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setIsLoading(true);
            fetchParticipantData();
            fetchStaticFileLink(questionnaireFileKey, setQuesitionnaireFileLink);
            fetchStaticFileLink(sampleSurveyFileKey, setSampleSurveyFileLink);
            setIsLoading(false);
        }
        return () => { isMounted = false };
        // eslint-disable-next-line
    }, [participantId]);

    return (
        <>
            <NavBar landingPagePath={`/ecs-survey/${participantId}`} />
            {(participantData && !isLoading) ? <div className='page-width'>
                <QuestionnaireUploadModal show={showUploadModal} onClose={() => setShowUploadModal(false)} participantId={participantId} participantName={participantData.participantName} />
                <div className='survey-participant-console-container'>
                    <h3 className='title-text'>
                        <span className='hello-text'>Hello</span>, <span className='participant-name-text'>{participantData.participantName}</span>
                    </h3>
                    <div className='welcome-text'>
                        Welcome to the 2024 FW Cook Executive Compensation Survey Portal!
                    </div>
                    <div className='survey-participant-console-content'>
                        {participantData.earlySurveyResultEligible ? (
                            <>
                                <div className='section'>
                                    <h4 className='section-heading'>Survey Access</h4>
                                    <p>Access the relevant survey files and related materials for download here</p>
                                    <a href="#self" ref={earlySurveyResultDonwnloadLinkRef} download id="download-questionnaire" hidden={true}>hidden</a>
                                    <div className='actions-buttons-container' style={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <div className='tooltip-container'>
                                            <button className='btn btn-primary download' onClick={handleDownloadingEarlySurveyResult}>Download Early Result</button>
                                            <span className='tooltip-text'>Download Early Survey Result - General Industry</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='section'>
                                    <h4 className='section-heading'>Questionnaire Submission Portal</h4>
                                    <ul>
                                        <li>Starting <b>April 15th</b>, securely upload your completed survey questionnaire</li>
                                        <li>We encourage early submission, no later than the requested deadline (<b>May 15th</b> or <b>August 15th</b>, depending on your company's latest annual long-term incentive grant cycle)</li>
                                        <li>Upon submission, you'll receive a message indicating successful upload or any necessary corrections required before submission</li>
                                        <li>Our processing team will then review your submission and provide feedback or confirm acceptance</li>
                                    </ul>
                                    {participantData.earlySurveyResultEligible && <div className='actions-buttons-container' style={{ display: 'flex', flexDirection: 'row', marginLeft: "1rem" }}>
                                        <button className='btn btn-secondary upload' onClick={() => setShowUploadModal(true)}>Upload Questionnaire</button>
                                    </div>}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='section'>
                                    <div className='actions-buttons-container' style={{ display: 'flex', flexDirection: 'row' }}>
                                        <a href="#self" ref={questionnaireDonwnloadLinkRef} download id="download-questionnaire" hidden={true}>hidden</a>
                                        <button className='btn btn-primary download' onClick={handleDownloadingQuestionnaire}>Download Questionnaire</button>
                                        <button className='btn btn-primary upload' onClick={() => setShowUploadModal(true)}>Upload Questionnaire</button>
                                    </div>
                                    <p>For your convenience, you may download a <a href={sampleSurveyFileLink.url} onClick={sampleSurveyDownloadedEvent} className='link' target='_blank' rel="noreferrer">sample questionnaire</a> with illustrative data to assist in completing your survey questionnaire</p>
                                </div>
                                <div className='section questionnaire-access'>
                                    <h4 className='section-heading'>Questionnaire Submission Portal</h4>
                                    <ul>
                                        <li>Starting <b>April 15th</b>, securely upload your completed survey questionnaire</li>
                                        <li>We encourage early submission, no later than the requested deadline (<b>May 15th</b> or <b>August 15th</b>, depending on your company's latest annual long-term incentive grant cycle)</li>
                                        <li>Upon submission, you'll receive a message indicating successful upload or any necessary corrections required before submission</li>
                                        <li>Our processing team will then review your submission and provide feedback or confirm acceptance</li>
                                    </ul>
                                </div>
                                <div className='section'>
                                    <h4 className='section-heading'>Survey Access</h4>
                                    <p>Once finalized, access the relevant survey files and related materials for download here</p>
                                </div>
                            </>
                        )}
                        <div className='section'>
                            <h4 className='section-heading'>Contact Us</h4>
                            <p>For any queries, reach out to {(participantData?.accountExecutiveEmail) ?
                                <span> your account representative <a href={`mailto:${participantData.accountExecutiveEmail}`} onClick={emailAccountExecutiveEvent} className='link'>{participantData.accountExecutiveName}</a> or</span> : null}
                                <span> <a href='mailto:FWCSurvey@fwcook.com' onClick={emailFwcSurveyEvent} className='link'>FWCSurvey@fwcook.com</a></span></p>
                        </div>
                        <div className='thank-you-text'>
                            Thank you for your contribution to shaping the future of executive compensation practices with the FW Cook Executive Compensation Survey
                        </div>
                    </div>
                </div>
            </div> : <Spinner animation="border" variant="primary" />}
        </>
    )
}