import React, { useEffect, useReducer } from 'react';
import { Spinner } from "react-bootstrap";
import { useKeyPress } from "../../../Hooks/useKeyPress";

import './styles.scss';

const SearchResultDisplay = ({ searchResults, searchTerm, resultItem, loadingResults, showSearchResults, handleSelection, handleUpdatingSelectedIndex }) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'arrowUp':
                return {
                    selectedIndex:
                        state.selectedIndex !== 0 ? state.selectedIndex - 1 : searchResults.length - 1,
                };
            case 'arrowDown':
                return {
                    selectedIndex:
                        state.selectedIndex !== searchResults.length - 1 ? state.selectedIndex + 1 : 0,
                };
            case 'select':
                return { selectedIndex: action.payload };
            default:
                throw new Error();
        }
    };

    const initialState = { selectedIndex: 0 };

    const arrowUpPressed = useKeyPress('ArrowUp');
    const arrowDownPressed = useKeyPress('ArrowDown');
    const [state, dispatch] = useReducer(reducer, initialState);

    const selectedIndex = state.selectedIndex;

    useEffect(() => {
        if (arrowUpPressed) {
            dispatch({ type: 'arrowUp' });
        }
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed) {
            dispatch({ type: 'arrowDown' });
        }
    }, [arrowDownPressed]);

    useEffect(() => {
        handleUpdatingSelectedIndex(selectedIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);


    useEffect(() => {
        if (searchResults.length > 0) {
            dispatch({ type: 'select', payload: 0 });
        }
    }, [searchResults]);

    return (
        <div className="search-results" style={{ display: showSearchResults ? 'block' : 'none' }}>
            {searchTerm && searchResults.length > 0 && !loadingResults ?
                <div tabIndex={-1}>
                    {searchResults.map((result, index) => {
                        return <div key={index} className="container">
                            <div className="row search-result"
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: index === state.selectedIndex ? '#eee' : '',
                                }}>
                                <div className="col-md-8"
                                    style={{ padding: '5px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSelection("click", index);
                                    }}>
                                    {result[resultItem]}
                                </div>
                            </div>
                        </div>
                    })}
                </div> : null}
            {searchTerm && searchResults === 0 && !loadingResults ?
                <div className="search-result">
                    No results found
                </div>
                : null}
            {loadingResults && searchTerm && searchTerm.length > 0 ?
                <div className="search-result">
                    Loading Results...
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"></Spinner>
                </div> : null}
        </div>);
}

export default SearchResultDisplay;