import { API, Auth, Storage } from 'aws-amplify';
import React, { useCallback, useState } from 'react';
import { Button, Form, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { getAuthToken } from '../../utils/AuthUtils';

import './styles.scss';

const UploadModal = ({ templateId, templateTitle, templateType, show, onClose }) => {
    const [files, setFiles] = useState(null);
    const [error, setError] = useState(null);

    const [uploading, setUploadingStatus] = useState(false);

    const [displaySuccessMessages, setDisplaySuccessMessages] = useState(false);
    const [successMessage, setSuccessMessage] = useState("")
    const [reviewedDataUpload, setReviewedDataUpload] = useState(false);
    const [ignoreErrorChecking, setIgnoreErrorChecking] = useState(false);

    const [isUploadActive, setUploadActive] = useState(true);

    const [displayErrorAlert, setErrorAlertDisplayStatus] = useState(false);
    const [fileErrors, setFileErrors] = useState(null);
    const [surveyParticipantId, setSurveyParticipantId] = useState(null);

    const clearFile = (fileName) => {
        setFiles(files.filter(file => file.name !== fileName));
    }

    const handleUpload = () => {
        processUpload();
    }

    const disableUpload = () => {
        setUploadActive(false);
    }

    const enableUpload = () => {
        setUploadActive(true);
    }

    const isSurveyTemplate = () => {
        if (templateType === "Survey") {
            return true;
        }
        return false;
    }

    const processUpload = async () => {
        if (!files || files.length === 0) {
            setError("No files to upload");
            setErrorAlertDisplayStatus(true);
            return;
        }

        setUploadingStatus(true);
        disableUpload();

        try {
            const filesToUpload = [...files];
            let allSuccess = true;

            for (let fileIndex = 0; fileIndex < filesToUpload.length; fileIndex++) {
                const file = filesToUpload[fileIndex];
                const uniqueFileName = await storeFileInS3(file);
                const failure = await processFile(file.name, uniqueFileName, reviewedDataUpload, ignoreErrorChecking, templateId);
                if (failure) {
                    allSuccess = false;
                    break;
                }

                if (fileIndex <= filesToUpload.length - 1) {
                    const unprocessedFiles = filesToUpload.slice(fileIndex + 1);
                    setFiles(unprocessedFiles);
                }
            }
            if (allSuccess) {
                setSuccessMessage('Files uploaded successfully');
                setDisplaySuccessMessages(true);
            }
            setUploadingStatus(false);
            enableUpload();
        } catch (error) {
            setError(error.message);
            setErrorAlertDisplayStatus(true);
            setUploadActive(true);
            setUploadingStatus(false);
        }
    };

    async function storeFileInS3(file) {
        const timestamp = new Date().getTime();
        const userId = await Auth.currentAuthenticatedUser().then(user => user.username);
        const uniqueFileName = `${timestamp}-${file.name}`;
        await Storage.put(`${userId}/${uniqueFileName}`, file, {
            contentType: file.type
        });
        return uniqueFileName;
    }

    const processFile = async (actualFileName, uniqueFileName, reviewedDataUpload, ignoreErrorChecking, templateId) => {
        try {
            const authToken = await getAuthToken();
            const response = await API.post('AnnualCompensations', '/compensations', {
                headers: { 'token': authToken },
                body: {
                    templateId,
                    reviewedDataUpload,
                    fileName: uniqueFileName,
                    ignoreErrorChecking,
                    surveyParticipantId
                }
            });
            if (!response.complete) {
                await createValidationsErrorFile(response, actualFileName);
            }
            return !response.complete;
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setError(errorMessage);
            setErrorAlertDisplayStatus(true);
            return true;
        }
    };

    const getErrorMessage = (error) => {
        if (error !== undefined && error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined) {
            return error.response.data.message;
        }
        console.log(JSON.stringify(error));
        return "An error occurred while uploading compensation data.";
    };

    const createValidationsErrorFile = async (response, fileName) => {
        const XLSX = await import("xlsx");
        setError("An error occurred while validating " + fileName + ". See attached file for details");
        setErrorAlertDisplayStatus(true);
        const workbook = XLSX.read(response.result, { type: "base64", cellDates: true, cellFormula: true })
        const name = fileName.split(".")[0]
        XLSX.writeFile(workbook, name + "_Validation_Errors.xlsx");
    };

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            setError(null);
            setFiles(acceptedFiles);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.ms-excel': ['.xls', '.xlsx'],
            'text/plain': ['.xls', '.xlsx']
        }
    });

    const resetModalState = () => {
        setUploadActive(true);
        setFiles(null);
        setError(null);
        setErrorAlertDisplayStatus(false);
        setFileErrors(null);
        setDisplaySuccessMessages(false);
        setReviewedDataUpload(false);
        setUploadingStatus(false);
        setIgnoreErrorChecking(false);
        setSurveyParticipantId(null);
    }

    const handleClose = () => {
        resetModalState();
        onClose();
    }

    return (
        <div className="upload-modal">
            <Modal show={show} onHide={handleClose} size="md" contentClassName="modal-container">
                <Modal.Header closeButton>
                    <h5 className="mb-0">Upload {templateTitle} Data</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-modal-body">

                        <div {...getRootProps({ className: isUploadActive ? 'dropzone' : 'dropzone disabled' })} style={{ "border": "2px dotted", "borderRadius": "5px", "cursor": "pointer" }}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p className="drop-zone-text">Drop the files here ...</p> :
                                    <p className="drop-zone-text">Drag 'n' drop some files here, or click to select files</p>
                            }
                        </div>
                        {isSurveyTemplate() ? <div className='survey-participantId-input' style={{"padding": "0.5rem 0 0.5rem 0"}}><FormGroup controlId="participantId">
                            <Form.Label>Survey Participant ID</Form.Label>
                            <Form.Control
                                disabled={!isSurveyTemplate()}
                                className="participantId-input"
                                type="text"
                                value={surveyParticipantId}
                                onChange={(e) => setSurveyParticipantId(e.target.value)}
                            ></Form.Control>
                        </FormGroup></div> : ""}
                        <FormGroup controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Reviewed Data Re-Upload" checked={reviewedDataUpload} onChange={e => setReviewedDataUpload(e.target.checked)} />
                        </FormGroup>
                        <FormGroup controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Ignore Error Checking" checked={ignoreErrorChecking} onChange={e => setIgnoreErrorChecking(e.target.checked)} />
                        </FormGroup>
                        {files && <aside>
                            <p style={{ "marginTop": "0.5rem", display: files.length > 0 ? '' : 'none' }}>Selected File(s):</p>
                            <ul>{files.map(file => (
                                <li key={file.name}>
                                    {file.name} - {file.size / 1000} kb
                                    <button type="button" className="close" aria-label="Close" onClick={() => clearFile(file.name)} style={{ "backgroundColor": "#fff", "border": "none" }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </li>
                            ))}</ul>
                        </aside>}
                        {displaySuccessMessages && <div className="alert alert-success mt-2 alert-dismissible" role="alert">
                            <p>{successMessage}</p>
                            <button type="button" className="btn-close" onClick={() => setDisplaySuccessMessages(!displaySuccessMessages)} aria-label="Close"></button>
                        </div>}
                        {error && <div className="alert alert-danger mt-2 alert-dismissible" role="alert" style={{ display: displayErrorAlert ? '' : 'none' }}>
                            {error}
                            <button type="button" className="btn-close" onClick={() => setErrorAlertDisplayStatus(!displayErrorAlert)} aria-label="Close"></button>
                        </div>}
                        {fileErrors && <div className='alert alert-warning mt-2' role="alert" style={{ marginBottom: "unset" }}>
                            <strong>Your file has errors:</strong>
                            {Array.from(fileErrors.entries()).map(([key, values]) => (
                                <div key={key.file}>
                                    <i style={{ margin: "10px", padding: "5px" }}><b>File:</b> {key.file}</i><br />
                                    <i style={{ margin: "10px", padding: "5px" }}><b>Sheet:</b> {key.sheet}</i><br />
                                    <i style={{ margin: "10px", padding: "5px" }}><b>No. of errors:</b> {Array.isArray(values) ? values.length : 1}</i>
                                    {Array.isArray(values) ?
                                        <ul>{values.map((error, index) => (
                                            <li key={`${index}-${key}`}>
                                                {error}
                                            </li>
                                        ))}</ul> : <ul><li>{values}</li></ul>}
                                </div>
                            ))}
                        </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="btn btn-primary btn-modal-upload" onClick={handleUpload}
                        style={{ "width": uploading ? "30%" : "25%" }} disabled={!files || files.length === 0 || !isUploadActive || isDragReject}>
                        {uploading ? <span>Uploading...<Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true" /></span> :
                            <span>Upload <i className="fa fa-upload"></i></span>}
                    </Button>
                    <Button type="button" variant="btn btn-primary" onClick={handleClose} style={{ "width": "20%" }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UploadModal;