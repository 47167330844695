import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { getAuthToken } from "../../utils/AuthUtils";
import ClientNameInputComponent from "./ClientNameInputComponent/ClientNameInputComponent";
import ClientOwnerInputComponent from "./ClientOwnerInputComponent/ClientOwnerInputComponent";
import { compressDownloadRequestData } from '../../utils/WebSocketUtils';

import './styles.scss';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_API;

const ClientNameForDataExport = ({ show, onClose, compensationIds, selectedTemplate, successfulDownload }) => {
  const [billableType, setBillableType] = useState('');
  const [billableTypeNotEmpty, setBillableTypeNotEmpty] = useState(true)
  const billableTypeVals = Object.freeze({ billable: "BILLABLE", nonBillableRFP: "NON_BILLABLE_RFP", nonBillableOther: "NON_BILLABLE_OTHER" });
  const [userNotes, setUserNotes] = useState('');
  const [characterLimit, setCharacterLimit] = useState(250);
  const [requiredNonBillable, setRequiredNonBillable] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setError] = useState('');
  const [closedDisabled, setClosedDisabled] = useState(false);

  const [selectedClientOwnerUserRecord, setSelectedClientOwnerUserRecord] = useState({});
  const [clientOwnerNameErrorMessage, setClientOwnerNameErrorMessage] = useState('');
  const [clientOwnerNameInvalid, setClientOwnerNameInvalid] = useState(false)
  const [clientOwnerNameLookupTerm, setClientOwnerNameLookupTerm] = useState("")

  const [clientName, setClientName] = useState("");
  const [clientNameInvalid, setClientNameInvalid] = useState(false);

  const handleSubmit = async () => {
    if ((!clientName || clientName.length < 3) && billableType === billableTypeVals.billable) {
      setClientNameInvalid(true)
      return;
    } else {
      setClientNameInvalid(false);
    }
    if (billableType === "") {
      setBillableTypeNotEmpty(false)
      return;
    } else {
      setBillableTypeNotEmpty(true);
    }
    if (billableType === billableTypeVals.billable) {
      if (clientOwnerNameLookupTerm === "" || clientOwnerNameLookupTerm === undefined
        || clientOwnerNameLookupTerm === null || clientOwnerNameLookupTerm.split(" ").length < 2) {
        setClientOwnerNameInvalid(true)
        setClientOwnerNameErrorMessage('Client Owner Name should be in the format "First Last"');
        return;
      }
      if (selectedClientOwnerUserRecord === undefined || selectedClientOwnerUserRecord === null
        || Object.keys(selectedClientOwnerUserRecord).length === 0) {
        setClientOwnerNameInvalid(true)
        setClientOwnerNameErrorMessage('Client Owner Name not found. Please select a valid user');
        return;
      }
    }
    if (userNotes.length === 0 && billableType !== billableTypeVals.billable) {
      setRequiredNonBillable(true);
      return;
    }
    setClientOwnerNameInvalid(false);
    setRequiredNonBillable(false);
    setClientNameInvalid(false);
    await handleDownload(billableType, userNotes);
  }

  const validateSelection = () => {
    if (compensationIds.size === 0) {
      setShowError(true);
      setError("Please select at least one row to download");
      return false;
    }
    return true;
  };

  const prepareWebSocketConnection = async (request) => {
    const authToken = await getAuthToken();
    const url = `${WEBSOCKET_URL}/?token=${authToken}`;
    const socket = new WebSocket(url);

    socket.addEventListener("open", () => handleWebSocketOpen(socket, request));
    socket.addEventListener("error", () => handleWebSocketError(socket));
    socket.addEventListener("close", handleWebSocketClose);
    socket.addEventListener("message", (event) => handleWebSocketMessage(socket, event));

    return socket;
  };

  const handleWebSocketOpen = (socket, request) => {
    const eventStr = compressDownloadRequestData(request);
    const textEncoder = new TextEncoder();
    if (textEncoder.encode(eventStr).length > 128000) {
      handleError("Download request is too large. Please select fewer rows or tickers.");
      socket.close();
    } else {
      socket.send(eventStr);
    }
  };

  const handleWebSocketError = (socket) => {
    handleError("An error occurred downloading data");
    console.log("WebSocket error");
    socket.close();
  };

  const handleWebSocketClose = () => {
    console.log("WebSocket connection closed");
  };

  const handleWebSocketMessage = (socket, event) => {
    const data = JSON.parse(event?.data);
    if (data?.url) {
      handleSuccess(data.url);
      socket.close();
    } else if (data?.error) {
      handleError(data.error);
      socket.close();
    }
  };

  const handleSuccess = (url) => {
    handlDownloadUrl(url);
    setDownloading(false);
    setClosedDisabled(false);
    successfulDownload(true);
    handleClose();
  };

  const handleError = (errorMessage) => {
    setDownloading(false);
    setClosedDisabled(false);
    setShowError(true);
    setError(errorMessage);
  };

  const handleDownload = async (billableType, userNotes) => {
    if (!validateSelection()) return;

    setError(null);
    setShowError(false);
    setDownloading(true);
    setClosedDisabled(true);
    successfulDownload(false);

    const request = {
      selectedCompensationIds: [...compensationIds],
      templateId: selectedTemplate.templateId,
      billableType,
      clientName,
      userNotes,
      clientOwnerName: selectedClientOwnerUserRecord.userName,
      clientOwnerUserId: selectedClientOwnerUserRecord.userId,
      clientOwnerEmail: selectedClientOwnerUserRecord.userEmail,
    };

    try {
      await prepareWebSocketConnection(request);
    } catch (error) {
      handleError("An error occurred while downloading data. Please try again later.");
    }
  }

  const handlDownloadUrl = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const handleChangeBillableType = (event) => {
    setBillableType(event.target.value);
    setBillableTypeNotEmpty(true);
    setClientNameInvalid(false);
    setRequiredNonBillable(false);
    setClientName("");
    setClientOwnerNameLookupTerm("")
    setSelectedClientOwnerUserRecord({});
  }

  const handleNotesEntry = (event) => {
    setUserNotes(event.target.value);
    let limit = 250 - event.target.value.length
    setCharacterLimit(limit);
    setRequiredNonBillable(false);
  }

  const handleClose = () => {
    setBillableType('');
    setUserNotes('');
    setBillableTypeNotEmpty(true);
    setCharacterLimit(250);
    setRequiredNonBillable(false);
    setClientOwnerNameInvalid(false)
    onClose();
    setShowError(false);
    setError('');
    setClientOwnerNameLookupTerm("")
    setSelectedClientOwnerUserRecord({});
    setClientNameInvalid(false);
    setClientName("");
  };

  return (
    <Modal show={show} onHide={handleClose} size="md" contentClassName="modal-container">
      <Modal.Header closeButton>
        <h5 className='mb-0'>Data Billing</h5>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label>Billable Type</Form.Label>
          <Form.Control as="select" required onChange={(event) => handleChangeBillableType(event)} isInvalid={!billableTypeNotEmpty}>
            <option value="">Select a value</option>
            <option value={billableTypeVals.billable}>Billable</option>
            <option value={billableTypeVals.nonBillableRFP}>Non-billable: RFP</option>
            <option value={billableTypeVals.nonBillableOther}>Non-billable: Other</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control.Feedback type="invalid">Please select a billable type</Form.Control.Feedback>
          {billableType === billableTypeVals.billable ?
            <>
              <ClientNameInputComponent
                clientName={clientName}
                setClientName={setClientName}
                clientNameInvalid={clientNameInvalid}
                setClientNameInvalid={setClientNameInvalid} />
              <ClientOwnerInputComponent
                clientOwnerNameLookupTerm={clientOwnerNameLookupTerm}
                setClientOwnerNameLookupTerm={setClientOwnerNameLookupTerm}
                setSelectedClientOwnerUserRecord={setSelectedClientOwnerUserRecord}
                clientOwnerNameInvalid={clientOwnerNameInvalid}
                setClientOwnerNameInvalid={setClientOwnerNameInvalid}
                clientOwnerNameErrorMessage={clientOwnerNameErrorMessage}
              />
            </> : ""}
        </Form.Group>
        <Form.Group className="mb-4" controlId="clientNameforDataExportForm.userNotesInput">
          <Form.Label>User Notes</Form.Label>
          <Form.Control
            placeholder="Please enter any notes for this download"
            onChange={(event) => handleNotesEntry(event)}
            as="textarea"
            maxLength="250"
            isInvalid={requiredNonBillable}
          />
          <span className='character-limit-count'>Character limit: {characterLimit}</span>
          <Form.Control.Feedback type="invalid">
            User Notes required for non-billable downloads
          </Form.Control.Feedback>
        </Form.Group>
        {showError ? <div className="alert alert-danger" role="alert">{errorMessage}</div> : null}
      </Modal.Body>
      <Modal.Footer>
        {downloading ?
          <Button variant="btn btn-primary"
            disabled={true}>
            Downloading...
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Button> :
          <Button variant="btn btn-primary"
            type="submit"
            onClick={handleSubmit}
            style={{ "width": "25%" }}>
            Download
          </Button>}
        <Button variant="btn btn-secondary" onClick={handleClose} style={{ "width": "20%" }} disabled={closedDisabled}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClientNameForDataExport;