import { API } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { getAuthToken } from "../../utils/AuthUtils";
import DataRequestStatusDropDown from "../DataRequestStatusDropDown/DataRequestStatusDropDown";
import GenericGridComponent from "../../GenericGridComponent/GenericGridComponent";
import ViewDataRequestModal from "../ViewDataRequestModal/ViewDataRequestModal";
import "./styles.scss";

const MyDataRequests = () => {
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);

    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);
    const [error, setError] = useState("");

    const [selectedRequestStatus, setSelectedRequestStatus] = useState("PENDING"); // Default to "PENDING"

    const [viewDataRequestModalShow, setViewDataRequestModalShow] = useState(false);
    const [selectedDataRequest, setSelectedDataRequest] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const dataActions = (_, dataRequest) => {
        return (
            <Button variant="primary view-request-btn" size="sm" className="mr-2" onClick={() => handleViewDataRequest(dataRequest)}>
                <i className="fa fa-eye"></i>
            </Button>
        );
    };

    const columns = [{
        "key": "clientCompanyId",
        "name": "Client Company ID",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    },
    {
        "key": "clientName",
        "name": "Client Name",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    },
    {
        "key": "deadline",
        "name": "Deadline",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    },
    {
        "key": "dateRequested",
        "name": "Date Requested",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    },
    {
        "key": "requestStatus",
        "name": "Status",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    },
    {
        "key": "actions",
        "sortable": false,
        "filterable": false,
        "formatter": ({ column, row }) => {
            return (
                dataActions(column, row)
            );
        }
    }
    ];

    const handleViewDataRequest = (dataRequest) => {
        setSelectedDataRequest(dataRequest);
        setViewDataRequestModalShow(true);
    }

    const processSettingData = (response) => {
        if (response === undefined || response === null) {
            return;
        }
        setData(response);
    }

    const getData = async (requestStatus) => {
        setLoading(true);
        const authToken = await getAuthToken();
        setErrorDisplayStatus(false);
        setError("");
        const basePath = '/data-requests/user';
        const requestUriPath = requestStatus === "" ? basePath : `${basePath}?status=${requestStatus}`;
        API.get("AnnualCompensations", requestUriPath,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Token": authToken
                }
            }).then(response => {
                processSettingData(response);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error !== undefined && error.response !== undefined &&
                    error.response.data !== undefined && error.response.data.message !== undefined) {
                    setError(error.response.data.message);
                    setErrorDisplayStatus(true);
                } else {
                    setError("An error occurred while retrieving company request data. Please try again later.");
                    setErrorDisplayStatus(true);
                }
            });
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setData([]);
            setLoading(false);
            setErrorDisplayStatus(false);
            setError("");
            getData(selectedRequestStatus);
            setCurrentPage(1);
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRequestStatus]);

    return (
        <div className="my-data-requests-container">
            <div className="page-headers">
                <h2>My Data Requests</h2>
            </div>
            <ViewDataRequestModal show={viewDataRequestModalShow} handleClose={() => setViewDataRequestModalShow(false)} data={selectedDataRequest} />
            <div className="alert-messages">
                {errorDisplayStatus && <Alert variant="danger" dismissible>{error}</Alert>}
            </div>
            {loading ? <Spinner animation="border" variant="primary" className="spinner-blue" /> :
                <div className='requests-content'>
                    <DataRequestStatusDropDown requestStatus={selectedRequestStatus} onRequestStatusChange={setSelectedRequestStatus} />
                    {data.length > 0 ?
                        <GenericGridComponent
                            columns={columns}
                            data={data}
                            fileName={"Data_Requests"}
                            itemsName={"Data Requests"}
                            selectedCurrentPage={currentPage}
                            onCurrentPageSelect={(page) => setCurrentPage(page)}
                        />
                        : <Alert style={{ marginTop: "1rem" }} variant="info">No {selectedRequestStatus} requests</Alert>}
                </div>
            }
        </div>
    );
}

export default MyDataRequests;