import { API, Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useStateStore } from "../AppState";
import CompensationGrid from "../CommonComponents/CompensationGrid/CompensationGrid";
import UploadModal from "../CompensationConsole/UploadModal/UploadModal";
import { getAuthToken } from "../utils/AuthUtils";
import { compressDownloadRequestData } from '../utils/WebSocketUtils';

import "./styles.scss";
import { HIDDEN_COLUMNS } from "../Constants";

const nonNumericCharactersPattern = /[^0-9]/g;

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_API;

const RANGE_TYPES = Object.freeze({
    REVENUE: "REVENUE",
    MARKET_CAP: "MARKET_CAP",
    TOTAL_ASSETS: "TOTAL_ASSETS"
});

const SurveyDataConsole = () => {
    const [surveyTemplateNames, setSurveyTemplateNames] = useState([]);
    const [surveyYear, setSurveyYear] = useState("");

    const [surveyData, setSurveyData] = useState([]);
    const [selectedSurveyRows, setSelectedSurveyRows] = useState(new Set());

    const [rangeStart, setRangeStart] = useState(0);
    const [rangeEnd, setRangeEnd] = useState(0);

    const [rangeType, setRangeType] = useState("");

    const [rangeStartValid, setRevenueRangeStartValid] = useState(true);
    const [rangeEndValid, setRevenueRangeEndValid] = useState(true);

    const [selectedSurveyTemplateSummary, setSelectedSurveyTemplateSummary] = useState(null);
    const [selectedSurveyTemplateId, setSelectedSurveyTemplateId] = useState(null);
    const [surveyDataGridColumns, setSurveyDataGridColumns] = useState([]);

    const [isFormValidated, setIsFormValidated] = useState(false);

    const { setLoadingStatus } = useStateStore();
    const [searching, setSearching] = useState(false);
    const [downloadingInput, setDownloadingInput] = useState(false);
    const [downloadingOutput, setDownloadingOutput] = useState(false);

    const [error, setError] = useState(null);
    const [successMessage, setSuccess] = useState(null);

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isAdminUser, setIsAdminUser] = useState(false);

    const getSurveyTemplateSummary = async (templateId) => {
        setSuccess(null);
        setLoadingStatus(true);
        const url = `/survey/template-summary/${templateId}`;
        const authToken = await getAuthToken();
        API.get('AnnualCompensations', url, {
            headers: {
                'Token': authToken
            }
        }).then((response) => {
            setLoadingStatus(false);
            handleSettingTemplateData(response);
        }).catch((error) => {
            setLoadingStatus(false);
            if (error !== undefined && error.response !== undefined &&
                error.response.data !== undefined && error.response.data.message !== undefined) {
                setError(error.response.data.message);
            }
        });
    }

    const handleSettingGridColumns = (data) => {
        const colDefinitions = data.summaryColDefinitions.filter(colDef => !HIDDEN_COLUMNS.includes(colDef.name));
        setSurveyDataGridColumns(colDefinitions);
    }

    const handleSelectedTemplateChange = async (templateId) => {
        setSelectedSurveyTemplateId(templateId);
        await getSurveyTemplateSummary(templateId);
    }

    const handleSettingTemplateData = (data) => {
        setSelectedSurveyTemplateSummary(data);
        handleSettingGridColumns(data);
    }

    const handleSearchingData = async (e) => {
        setSuccess(null);
        e.preventDefault();
        if (e.target.checkValidity() === false) {
            e.stopPropagation();
            setIsFormValidated(true);
            return;
        }
        setSearching(true);
        const url = `/survey/compensations?templateId=${selectedSurveyTemplateId}&surveyYear=${surveyYear}`;
        const authToken = await getAuthToken();
        API.get('AnnualCompensations', url, {
            headers: {
                'Token': authToken
            }
        }).then((response) => {
            setSearching(false);
            if (response?.data?.length > 0) {
                setSurveyData(response.data);
                setError(null);
            } else {
                setError("No data found for the selected survey template and survey year");
            }
        }).catch((error) => {
            setSearching(false);
            if (error !== undefined && error.response !== undefined &&
                error.response.data !== undefined && error.response.data.message !== undefined) {
                setError(error.response.data.message);
            }
        });
    }

    const buildDownloadedFile = (response) => {
        const link = document.createElement('a');
        link.href = response.filePresignedUrl;
        link.setAttribute('download', selectedSurveyTemplateSummary.templateName + '.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const handleInputDataDownload = async () => {
        setSuccess(null);
        if (selectedSurveyRows.size === 0) {
            setError(true);
            setError("Please select at least one row to download");
            return;
        }
        setError(null);
        setDownloadingInput(true);

        const selecteCompIds = [...selectedSurveyRows];
        const request = {
            selectedCompensationIds: selecteCompIds,
            templateId: selectedSurveyTemplateId
        }

        try {
            const authToken = await getAuthToken();

            const url = WEBSOCKET_URL + "?token=" + authToken;
            const socket = new WebSocket(url);

            socket.addEventListener("open", (event) => {
                const eventStr = compressDownloadRequestData(request)
                const textEncoder = new TextEncoder()
                if (textEncoder.encode(eventStr).length > 128000) {
                    setDownloadingInput(false);
                    setError("Download request is too large. Plese select fewer rows or tickers.");
                    socket.close();
                } else {
                    socket.send(eventStr);
                }
            });

            socket.addEventListener("error", (event) => {
                setDownloadingInput(false);
                setError("An error occured downloading data");
                console.log("WebSocket error: " + event);
                socket.close();
            })

            socket.addEventListener("close", (event) => {
                console.log("WebSocket connection closed");
            })

            socket.addEventListener("message", (event) => {
                const data = JSON.parse(event?.data);
                if (data?.url) {
                    handlDownloadUrl(data.url);
                    setDownloadingInput(false);
                    socket.close();
                } else if (data?.error) {
                    setDownloadingInput(false);
                    setError(data.error);
                    socket.close();
                }
            });
        } catch (error) {
            setDownloadingInput(false);
            if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred while downloading survey input data. Please try again later.");
            }
        }
    }

    const handlDownloadUrl = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const validateRevenueRange = (e) => {
        e.preventDefault();
        setRevenueRangeStartValid(true);
        setRevenueRangeEndValid(true);
        if (rangeStart !== null && rangeEnd !== null) {
            if (rangeStart > rangeEnd) {
                setRevenueRangeStartValid(false);
                setRevenueRangeEndValid(false);
                e.stopPropagation();
                setIsFormValidated(true);
                return false;
            }
        }
        return true;
    }

    const handleSurveyOutputDownload = async (e) => {
        e.preventDefault();
        setSuccess(null);
        if (selectedSurveyRows.size === 0) {
            setError(true);
            setError("Please select at least one row for output");
            return;
        } else {
            setError("");
            setError(false);
        }

        if (!validateRevenueRange(e)) {
            return;
        }

        const selecteCompIds = [...selectedSurveyRows];

        const request = {
            selectedCompensationIds: selecteCompIds,
            templateId: selectedSurveyTemplateId,
            surveyYear
        }

        if (rangeStart > 0 && rangeEnd > 0) {
            request.rangeType = rangeType;
            request.rangeStart = rangeStart;
            request.rangeEnd = rangeEnd;
        }

        setIsFormValidated(false);
        setDownloadingOutput(true);

        try {
            const authToken = await getAuthToken();
            const response = await API.post("AnnualCompensations",
                "/survey/automate",
                {
                    headers: {
                        'Token': authToken
                    },
                    body: request
                });

            if (response === undefined || response === null || response === "") {
                setDownloadingOutput(false);
                setError("An error occurred while downloading survey output data. Please try again later.");
                return;
            }

            if (response.status !== 200) {
                setDownloadingOutput(false);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred while downloading survey  data. Please try again later.");
                }
                return;
            }
            buildDownloadedFile(response);

            setDownloadingOutput(false);
            setSuccess("Survey output data generated successfully");
        } catch (error) {
            setSuccess(null);
            setDownloadingOutput(false);
            if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred while generating survey output data. Please try again later.");
            }
        }
    }

    const handleSettingRange = (e, rangeBoundrySetter) => {
        const value = e.target.value;
        if (value === "") {
            rangeBoundrySetter("");
        } else {
            if (nonNumericCharactersPattern.test(value.toString())) {
                rangeBoundrySetter("");
                return;
            }
            if (Number(value) >= 0) {
                rangeBoundrySetter(Number(value));
            }
        }
    }

    const getAdminUser = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        setIsAdminUser(groups.includes("FWC_Admin"));
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const fetchSurveyTemplateNames = async () => {
                setLoadingStatus(true);
                const url = '/survey/template-names';
                const authToken = await getAuthToken();
                API.get('AnnualCompensations', url, {
                    headers: {
                        'Token': authToken
                    }
                }).then((response) => {
                    setLoadingStatus(false);
                    setSurveyTemplateNames(response.data);
                }).catch((error) => {
                    setLoadingStatus(false);
                    if (error?.response?.data?.message) {
                        setError(error.response.data.message);
                    }
                });
            }
            fetchSurveyTemplateNames();
        }
        return () => { isMounted = false };
    }, [setLoadingStatus]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSelectedSurveyRows(new Set());
        }
        return () => { isMounted = false };
    }, [surveyData]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSurveyData([]);
            setError(null);
            setSuccess(null);
            setSelectedSurveyRows(new Set());
            setSurveyDataGridColumns([]);
            setRangeStart(0);
            setRangeEnd(0);
            getAdminUser();
        }
        return () => { isMounted = false };
    }, [selectedSurveyTemplateId]);

    return (
        <div className="survey-data">
            <Form noValidate validated={isFormValidated} className="survey-data-form" onSubmit={handleSearchingData}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="surveyTemplateNames">
                            <Form.Label>Survey Template Name</Form.Label>
                            <Form.Control required as="select" onChange={(e) => handleSelectedTemplateChange(e.target.value)}>
                                <option value="">Select a Survey Template</option>
                                {surveyTemplateNames && surveyTemplateNames.length > 0 && surveyTemplateNames.map(({ templateName, templateId }) => {
                                    return (<option key={templateId} value={templateId}>{templateName}</option>);
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {selectedSurveyTemplateId && <Col md={6}>
                        <Form.Group controlId="surveyYear">
                            <Form.Label>Survey Year</Form.Label>
                            <Form.Control required type="number" value={surveyYear} onChange={(e) => setSurveyYear(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid survey year.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                </Row>
                {selectedSurveyTemplateId && <Row>
                    <Col md={3}>
                        <Form.Group controlId="rangeType">
                            <Form.Label>Range Type</Form.Label>
                            <Form.Control as="select" value={rangeType} onChange={(e) => setRangeType(e.target.value)}>
                                <option value="">Select a range type</option>
                                <option value={RANGE_TYPES.REVENUE}>Revenue</option>
                                <option value={RANGE_TYPES.MARKET_CAP}>Market Cap</option>
                                <option value={RANGE_TYPES.TOTAL_ASSETS}>Total Assets</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {rangeType !== "" ? <>
                        <Col md={3}>
                            <Form.Group controlId="rangeStart">
                                <Form.Label>Range Start (MIL.)</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={rangeStart || " "}
                                    step={1}
                                    onChange={(e) => handleSettingRange(e, setRangeStart)}
                                    isInvalid={!rangeStartValid}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Only positive numbers(non-decimal) are allowed. Range start cannot be greater than revenue range end.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group controlId="rangeEnd">
                                <Form.Label>Range End (MIL.)</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={rangeEnd || ""}
                                    min={1}
                                    step={1}
                                    onChange={(e) => handleSettingRange(e, setRangeEnd)}
                                    isInvalid={!rangeEndValid}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Only positive numbers(non-decimal) are allowed. Range end cannot be less than revenue range start.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </> : null}
                </Row>}
                <div className="survey-actions-container">
                    {selectedSurveyTemplateId ? <div className="survey-actions">
                        {searching ? <Button variant="primary" disabled>
                            Searching...
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        </Button> : <Button variant="primary" type="submit">
                            Search
                            <i className="fa fa-search"></i>
                        </Button>}
                        <Button variant="btn btn-primary" onClick={() => setShowUploadModal(true)}>
                            Upload
                            <i className="fa fa-upload"></i>
                        </Button>
                        <UploadModal templateId={selectedSurveyTemplateId} templateType={"Survey"} show={showUploadModal} onClose={() => setShowUploadModal(false)} />
                        {surveyData.length > 0 &&
                            <>
                                {downloadingInput ?
                                    <Button
                                        variant="btn btn-primary btn-download toolbar-btn"
                                        disabled={true}>
                                        Downloading...
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button> :
                                    <Button
                                        variant="btn btn-primary btn-download toolbar-btn"
                                        onClick={handleInputDataDownload}>
                                        INPUT DATA
                                        <i className="fa fa-download"></i>
                                    </Button>}
                            </>
                        }
                        {surveyData.length > 0 && isAdminUser &&
                            <>
                                {downloadingOutput ?
                                    <Button
                                        variant="btn btn-primary btn-primary btn-download"
                                        disabled={true}>
                                        Downloading...
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button> :
                                    <Button
                                        disabled={!isAdminUser}
                                        variant="btn btn-primary btn-primary btn-download"
                                        onClick={(e) => handleSurveyOutputDownload(e)}>
                                        OUTPUT DATA
                                        <i className="fa fa-download"></i>
                                    </Button>}
                            </>
                        }
                    </div> : null}
                </div>
            </Form>
            <div className="alerts-container">
                {error ? <div className="alert alert-danger" role="alert">{error}</div> : null}
            </div>
            <div className="success-container">
                {successMessage ? <div className="alert alert-success" role="alert">{successMessage}</div> : null}
            </div>
            <div className="survey-data-grid-container">
                {surveyData && surveyData.length > 0 && surveyDataGridColumns && surveyDataGridColumns.length > 0 ?
                    <CompensationGrid
                        templateTitle={selectedSurveyTemplateSummary.templateName}
                        columnDefinitions={surveyDataGridColumns}
                        compensations={surveyData}
                        rowKeyGetter={(row) => row.uniqueId}
                        selectedRows={selectedSurveyRows}
                        onSelectedRowsChange={(selectedRows) => setSelectedSurveyRows(selectedRows)}
                    ></CompensationGrid>
                    : null}
            </div>
        </div >
    );
};

export default SurveyDataConsole;