import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import SimpleGridComponent from "../SimpleGridComponent/SimpleGridComponent";
import { getAuthToken } from "../utils/AuthUtils";

import "./styles.scss";

const columns = [{
        "key": "companyName",
        "name": "Company Name",
        "sortable": true,
        "filterable": true,
        "minWidth": "14rem"
    },
    {
        "key": "companyId",
        "name": "Ticker / Company ID",
        "sortable": true,
        "filterable": true,
    },
    {
        "key": "latestFYE",
        "name": "Most Recent FYE",
        "sortable": true,
        "filterable": true,
    },
    {
        "key": "latestFilingDate",
        "name": "Most Recent Filing Date",
        "sortable": true,
        "filterable": true,
    }
    ];

const TemplateCompaniesGridModal = ({ templateId, templateName, showModal, onCloseModal, onSearch }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);
    const [error, setError] = useState("");
    const [showHeaderFilters, setShowHeaderFilters] = useState(true);
    const [selectedTickers, setSelectedTickers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const toggleHeaderFilters = () => {
        setShowHeaderFilters(!showHeaderFilters);
    }

    const exportToXlsx = async () => {
        const XLSX = await import("xlsx");
        const normalizedData = normalizeData(data);
        const ws = XLSX.utils.json_to_sheet(normalizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DATA");
        XLSX.writeFile(wb, `Companies_Available_${templateName}.xlsx`);
    }

    const normalizeData = (data) => {
        return data.map(company => {
            const rowRecord = {
                "Ticker": company.companyId,
                "Company Name": company.companyName,
                "Most Recent FYE": company.latestFYE,
                "Most Recent Filing Date": company.latestFilingDate
            };
            company.fyeList.forEach((fye, index) => {
                rowRecord[`FYE ${index + 1}`] = fye;
            });
            company.filingDateList.forEach((filingDate, index) => {
                rowRecord[`Filing Date ${index + 1}`] = filingDate;
            });
            return rowRecord
        })
    }

    const handleCompanySelection = (selectedCompanyIds) => {
        const tickers = data.filter(company => selectedCompanyIds.includes(company.id)).map(company => company.companyId);
        setSelectedTickers(tickers);
    }

    const handleSearch = () => {
        onSearch(selectedTickers);
        onCloseModal();
    }

    const handleClose = () => {
        onCloseModal();
        setErrorDisplayStatus(false);
        setError("");
        setLoading(false);
        setShowHeaderFilters(true);
        setSelectedTickers([]);
        setCurrentPage(1);
        setData([]);
    }

    const handleBringingSelectedCompaniesToTop = () => {
        const selectedCompanies = data.filter(company => selectedTickers.includes(company.companyId));
        const unselectedCompanies = data.filter(company => !selectedTickers.includes(company.companyId));
        setData([...selectedCompanies, ...unselectedCompanies]);
        setCurrentPage(1);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setLoading(false);
            setSelectedTickers([]);

            const getData = async () => {
                setLoading(true);
                const authToken = await getAuthToken();
                API.get(
                    "AnnualCompensations",
                    `/template/${templateId}/companies`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Token": authToken
                        }
                    }).then(response => {
                        if (response?.records?.length > 0) {
                            setData(response.records);
                        }
                        setLoading(false);
                    }).catch(error => {
                        setLoading(false);
                        if (error !== undefined && error.response !== undefined &&
                            error.response.data !== undefined && error.response.data.message !== undefined) {
                            setError(error.response.data.message);
                            setErrorDisplayStatus(true);
                        } else {
                            setError("An error occurred while retrieving compensation data. Please try again later.");
                            setErrorDisplayStatus(true);
                        }
                    });
            }
            if (showModal) {
                getData();
            }
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return (
        <Modal show={showModal} onHide={handleClose} size="xl" contentClassName="template-companies-grid-modal">
            <Modal.Header closeButton>
                <Modal.Title>{templateName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <div className="loading"> Loading...<output><Spinner as="span" animation="grow" size="sm" aria-hidden="true"/></output></div>}
                {!loading &&
                    <>
                        <div className="d-flex justify-content-start mb-2">
                            {data.length > 0 ? <Button title='Toggle Grid Header Filters' variant='primary' onClick={toggleHeaderFilters} style={{ padding: "0.25rem", maxWidth: "2rem", marginRight: "0.2rem" }}>
                                {showHeaderFilters ? <i className="fa fa-sort-amount-up-alt" style={{ padding: "0.25rem" }}></i> : <i className="fa fa-sort-amount-down-alt" style={{ padding: "0.25rem" }}></i>}
                            </Button> : null}
                            {data.length > 0 ? <Button title='Export grid to excel' variant='primary' onClick={() => exportToXlsx()} style={{ padding: "0.25rem", marginRight: "0.2rem", maxWidth: "2rem" }}>
                                <i className="fa fa-file-csv" style={{ padding: "0.25rem" }}></i>
                            </Button> : null}
                            {data.length > 0 ? <Button title='Show all selected companies' variant='primary' onClick={handleBringingSelectedCompaniesToTop} style={{ padding: "0.25rem", marginRight: "0.2rem", maxWidth: "2rem" }}>
                                <i className="fa fa-level-up-alt" style={{ padding: "0.25rem" }}></i>
                            </Button> : null}
                        </div>
                        <div className="companies-grid">
                            {data.length > 0 ? <SimpleGridComponent
                                columns={columns}
                                data={data}
                                selectedCurrentPage={currentPage}
                                onCurrentPageSelect={(page) => setCurrentPage(page)}
                                showHeaderFilters={showHeaderFilters}
                                itemsName="Companies"
                                onRowSelect={(ids) => handleCompanySelection(ids)}
                            /> : <Alert variant="info">No companies available for this template</Alert>}
                        </div>
                    </>
                }
                {errorDisplayStatus ? <Alert variant="danger">{error}</Alert> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSearch} disabled={selectedTickers.length === 0}>
                    Search<i className="fa fa-search" style={{ padding: "0.25rem" }}></i>
                </Button>
                <Button variant="secondary" onClick={onCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TemplateCompaniesGridModal;

TemplateCompaniesGridModal.propTypes = {
    templateId: PropTypes.string.isRequired,
    templateName: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired
}